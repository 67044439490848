<template>
  <div>
    <div :class="['flip-card', cards !== '' ? 'show' : '']">
      <div class="flip-card-inner">
        <div
          :class="[
            'flip-card-back',
            isWin ? `shadow` : '',
            isWin ? `flip-card-back-win` : 'flip-card-back-lose',
          ]"
        >
          <div
            :class="['card-icon', `icon-${cards[0]}`, `i-${cards[0]}`]"
          ></div>
          <div :class="['card-number', `${cards[0]}`]">
            {{ cardNumber }}
          </div>
          <!-- <div :class="['card-icon', `icon-H`, `i-red`]"></div>
          <div :class="['card-number', `red`]">4</div> -->
        </div>
        <div :class="[`flip-card-front`, `card-front-bg-${gameType}`]">
          <!-- <img src="images/back-card.png" /> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    cards: String,
    gameType: String,
    isWin: Boolean,
  },
  computed: {
    cardNumber: function () {
      if (this.cards === "") {
        return "";
      } else {
        if (this.cards[1] === "1") {
          if (this.cards[2] === undefined) {
            return "A";
          } else {
            return (
              this.cards[1] + (this.cards[2] != undefined ? this.cards[2] : "")
            );
          }
        } else {
          return (
            this.cards[1] + (this.cards[2] != undefined ? this.cards[2] : "")
          );
        }
      }
    },
  },

  data() {
    return {};
  },
};
</script>
<style lang="scss">
.shadow {
  display: grid;
  // place-items: center;
  color: #fff;
  position: relative;
  background: transparent;
}

.shadow::before,
.shadow::after {
  content: "";
  position: absolute;
  border-radius: 4px;
  background: linear-gradient(
    45deg,
    #ffffff,
    #ffffdc,
    #fff6d3,
    #ffffff,
    #ffffd2,
    #ffffff,
    #ffffbb,
    #ffffff
  );
  background-size: 800%;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  z-index: -100;
  animation: animate 25s linear infinite;
}

.shadow::after {
  filter: blur(15px);
}

@keyframes animate {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 400% 0;
  }

  100% {
    background-position: 0 0;
  }
}

#dot {
  background: linear-gradient(135deg, white 10%, transparent 0%) -20px 0,
    linear-gradient(225deg, white 10%, transparent 0%) -20px 0,
    linear-gradient(315deg, white 10%, transparent 0%),
    linear-gradient(45deg, white 10%, transparent 0%);
  background-size: 20px 20px;
  background-position: 3px 3px;
}
.flip-card {
  margin: 10px 10px 10px 10px;
  width: 45px;
  height: 65px;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.7s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.flip-card.show .flip-card-inner {
  transform: rotateY(180deg);
}

// .flip-card:hover .flip-card-inner {
//   transform: rotateY(180deg);
// }
.card-front-bg-banker {
  background-image: linear-gradient(
    45deg,
    #cc0001 35.71%,
    #ffffff 35.71%,
    #ffffff 50%,
    #cc0001 50%,
    #cc0001 85.71%,
    #ffffff 85.71%,
    #ffffff 100%
  );
  background-size: 19.8px 19.8px;
  border: solid white 2px;
}
.card-front-bg-player {
  background-image: linear-gradient(
    45deg,
    #022692 35.71%,
    #ffffff 35.71%,
    #ffffff 50%,
    #022692 50%,
    #022692 85.71%,
    #ffffff 85.71%,
    #ffffff 100%
  );
  background-size: 19.8px 19.8px;
  border: solid white 2px;
}
.flip-card-front,
.flip-card-back {
  border-radius: 4px;
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  img {
    height: 83px;
    width: 63px;
    margin: 3px;
  }
  .logo-card {
    position: absolute;
    height: 40px;
    width: 40px;
    margin: auto;
    top: 26px;
    right: 14px;
  }
  color: rgb(255, 255, 255);
}
.flip-card-back-win {
  background-color: white;
  color: white;
  transform: rotateY(180deg);
}
.flip-card-back-lose {
  opacity: 0.6;
  background-color: #b0b0b0;
  color: white;
  transform: rotateY(180deg);
}

.card-icon {
  width: 20px;
  height: 20px;
  margin: 6px 6px;
  /* background-color: black; */
}
.card-number {
  position: absolute;
  margin: 7px 2px 0px 0px;
  top: 14px;
  right: 0;
  font-size: 34px;
}
.i-red,
.i-H,
.i-D {
  background-color: red;
}
.i-black,
.i-C,
.i-S {
  background-color: black;
}
.red,
.H,
.D {
  color: red;
}
.black,
.C,
.S {
  color: black;
}
@media (max-width: 370px) {
  .flip-card {
    margin: 10px 10px 10px 10px;
    width: 30px;
    height: 40px;
    perspective: 1000px;
  }
  .card-icon {
    width: 10px;
    height: 10px;
    margin: 6px 6px;
    /* background-color: black; */
  }
  .card-number {
    position: absolute;
    margin: 0px 2px 0px 0px;
    top: 14px;
    right: 0;
    font-size: 22px;
  }
}
</style>
