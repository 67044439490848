<!-- 
	This is the dashboard page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

<template>
  <div>
    <template>
      <a-row type="flex" :gutter="[24, 24]" align="stretch">
        <a-col :span="24" :md="12">
          <a-card :bordered="false">
            <div class="card-title">
              <h6>รูปแบบการเล่น</h6>
            </div>
            <div class="card-content">
              <p>รูปแบบการเล่นหมายถึงลักษณะการเลือกฝ่ายเดิมพัน</p>
            </div>
            <a-col class="p-0" style="display: flex">
              <SettingRadioGroup
                ref="bet_side"
                :action="`getBetSide`"
                @getBetSide="getBetSide"
                :setting="setting[this.$route.params.game].bet_side"
                :model="bot_setting.playerBetType"
              />
            </a-col>
          </a-card>
        </a-col>

        <a-col :span="24" :md="12">
          <a-card :bordered="false">
            <div class="card-title">
              <h6>ฝากเงินเข้ากระเป๋าอัตโนมัติ</h6>
            </div>
            <div class="card-content">
              <p>ระบบฝากเงินเข้ากระเป๋าอัตโนมัติ</p>
            </div>
            <a-col class="p-0" style="display: flex">
              <a-switch
                :class="['mr-20 mb-20', 'ant-switch-checked-all']"
                :value="bot_setting.isAutoWithdraw"
                @change="onChangeAutoWithdraw"
                :checked="bot_setting.isAutoWithdraw"
              />
            </a-col>
          </a-card>
        </a-col>

        <a-col :span="24" :md="12">
          <a-card :bordered="false">
            <div class="card-title">
              <h6>รูปแบบการเดินเงิน</h6>
            </div>
            <div class="card-content">
              <p>
                การเดินเงินเป็นการบริหารความเสี่ยงในการลงทุนเพื่อให้สามารถทำกำไรจนถึงเป้าหมายที่กำหนด
              </p>
            </div>
            <a-col class="p-0">
              <a-radio-group
                size="small"
                button-style="solid"
                v-model="bot_setting.betMoneyType"
              >
                <a-radio-button
                  class="mb-5"
                  v-for="(data, i) in setting[this.$route.params.game]
                    .money_system"
                  :key="i"
                  :value="data.value"
                  >{{ data.title }}
                </a-radio-button>
              </a-radio-group>
              <Validate ref="validateMartingaleList" />
              <div v-if="bot_setting.betMoneyType === 'MARTINGALE'">
                <a-radio-group
                  size="small"
                  button-style="solid"
                  v-model="init_martingale"
                  @change="createMartingaleData(init_martingale)"
                >
                  <a-radio-button
                    class="mb-5"
                    v-for="(data, i) in martingale"
                    :key="i"
                    :value="data"
                    >{{ data }}</a-radio-button
                  >
                </a-radio-group>

                <a-col
                  :span="8"
                  class="p-0"
                  v-for="(data, i) in init_martingale"
                  :key="i"
                  :value="data"
                >
                  <a-input
                    type="number"
                    :placeholder="`ไม้ที่ ${i}`"
                    v-model.number="bot_setting.martingaleList[i]"
                  />
                </a-col>
              </div>
            </a-col>
          </a-card>
        </a-col>
        <a-col :span="24" :md="12">
          <a-card :bordered="false">
            <div class="card-title">
              <h6>เลือกชิพเริ่มต้น</h6>
            </div>
            <div class="card-content">
              <p>
                ข้อแนะนำการเลือกชิพนั้นไม่ควรกำหนดชิพเริ่มต้นเกิน 1% ของเงินทุน
                เช่นทุน 5,000 บาท ควรเริ่มเล่น 50 บาทเป็นต้น 50
              </p>
            </div>
            <a-col class="p-0" style="display: flex">
              <SettingRadioGroup
                :action="`getInitBet`"
                @getInitBet="getInitBet"
                :setting="setting[this.$route.params.game].init_bet"
                :model="bot_setting.betAmount"
                :custom="true"
              />
            </a-col>
            <Validate ref="validateInitBet" />
          </a-card>
        </a-col>
      </a-row>

      <a-row type="flex" :gutter="[24, 24]" align="stretch">
        <a-col :span="24" :md="12">
          <a-card :bordered="false">
            <div class="card-title">
              <h6>กำไรเป้าหมาย</h6>
            </div>
            <div class="card-content">
              <p>
                ข้อแนะนำการเลือกกำไรเป้าเริ่มต้นที่ 5-10% และเก็บเป็นรอบ
                หรือวันละครั้งเพื่อลงทุนระยะยาว
              </p>
            </div>
            <SettingInputPercent
              :action="`getProfitTarget`"
              @getProfitTarget="getProfitTarget"
              :model="bot_setting.profitTarget"
            />
            <Validate ref="validateProfitTarget" />
          </a-card>
        </a-col>

        <a-col :span="24" :md="12">
          <a-card :bordered="false">
            <div class="card-title">
              <h6>กำหนดขาดทุนไม่เกิน</h6>
            </div>
            <div class="card-content">
              <p>ข้อแนะนำ แนะนำให้ตั้ง 3 เท่าของกำไรที่เป้าหมาย</p>
            </div>

            <SettingInputPercent
              :action="`getLossLimit`"
              @getLossLimit="getLossLimit"
              :model="bot_setting.lossLimit"
            />
            <Validate ref="validateLossLimit" />
          </a-card>
        </a-col>
      </a-row>
    </template>
    <!-- Sign In Form -->
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import settingBaccarat from "./json/settingBaccarat.json";
import settingRoulette from "./json/settingRoulette.json";
import settingTigerDragon from "./json/settingTigerDragon.json";
import SettingRadioGroup from "./SettingRadioGroup.vue";
import SettingInputPercent from "./SettingInputPercent.vue";
import Validate from "./Validate.vue";

export default Vue.extend({
  components: { SettingRadioGroup, SettingInputPercent, Validate },
  computed: {},
  created() {
    // this.bot_setting.playerBetType = "ALL";
    this.getBetSide(this.setting[this.$route.params.game].bet_side[0].value);
  },
  mounted() {
    for (var i = 1; i <= 18; i++) this.martingale.push(i);
    this.bot_setting.martingaleList = new Array(1)
      .fill(0)
      .map((x, idx) => this.bot_setting.betAmount * 2 ** idx);
  },
  data() {
    return {
      setting: {
        BACCARAT: settingBaccarat,
        ROULETTE: settingRoulette,
        DRAGON_TIGER: settingTigerDragon,
      },
      bot_setting: {
        betMoneyType: "NORMAL",
        betAmount: 10,
        profitTarget: { amount: 0, percent: 0 },
        lossLimit: { amount: 0, percent: 0 },
        martingaleList: [],
        labouchereList: [],
        playerBetType: "",
        isAutoWithdraw: false,

        // "RED_BLACK"
      },
      martingale: [],
      init_martingale: 1,
      form: {},
    };
  },

  methods: {
    onChange(checked) {
      console.log(`a-switch to ${checked}`);
    },
    setDefaultplayerBetType(val) {
      this.$refs.bet_side.setDefault(this.setting[val].bet_side[0].value);
      this.bot_setting.playerBetType = this.setting[val].bet_side[0].value;
    },

    createBot() {
      this.$http
        .post(
          `${process.env.VUE_APP_BACKEND_API_URL}/api/bot/${this.$route.params.game}?isMock=${this.$store.getters.getMock}`,
          this.bot_setting
        )
        .then(({ data }) => {
          console.log("create", data);
          window.location.reload();
        })
        .catch((err) => {
          return;
        });
    },

    createMartingaleData(value) {
      this.bot_setting.martingaleList = new Array(value)
        .fill(0)
        .map((x, idx) => this.bot_setting.betAmount * 2 ** idx);
    },
    checkValidate() {
      let validate = true;
      let checkBetAmout = this.bot_setting.betAmount;
      let checkProfitTarget = this.bot_setting.profitTarget.amount;
      let checkLosslimit = this.bot_setting.lossLimit.amount;
      let checkMartingaleList = this.bot_setting.martingaleList;

      if (checkBetAmout.toString() === "NaN") {
        this.$refs.validateInitBet.notValidate(false);
        validate = false;
      } else this.$refs.validateInitBet.notValidate(true);

      if (checkBetAmout < 10) {
        this.$refs.validateInitBet.notValidate(false, "ขั้นต่ำ 10");
        validate = false;
      } else this.$refs.validateInitBet.notValidate(true);

      if (checkProfitTarget <= 0 || checkProfitTarget.toString() === "NaN") {
        this.$refs.validateProfitTarget.notValidate(false);
        validate = false;
      } else this.$refs.validateProfitTarget.notValidate(true);

      if (checkLosslimit <= 0 || checkLosslimit.toString() === "NaN") {
        this.$refs.validateLossLimit.notValidate(false);
        validate = false;
      } else this.$refs.validateLossLimit.notValidate(true);

      if (checkMartingaleList.includes("")) {
        this.$refs.validateMartingaleList.notValidate(false);
        validate = false;
      } else this.$refs.validateMartingaleList.notValidate(true);

      if (validate === false) return;

      this.createBot();
    },
    getBetSide(val) {
      this.bot_setting.playerBetType = val;
    },
    // getMaximumBet(val) {
    //   console.log(val);
    //   this.bot_setting.maximum_bet = val;
    // },
    getInitBet(val) {
      this.bot_setting.betAmount = parseInt(val);
    },
    getCustomInitBet(val) {
      this.bot_setting.betAmount = parseInt(val);
    },
    getProfitTarget(val) {
      this.bot_setting.profitTarget = val;
    },
    getLossLimit(val) {
      this.bot_setting.lossLimit = val;
    },
    onChangeAutoWithdraw() {
      this.bot_setting.isAutoWithdraw = !this.bot_setting.isAutoWithdraw;
      console.log(this.bot_setting.isAutoWithdraw);
    },
  },
});
</script>

<style lang="scss"></style>
