<template>
  <div>
    <a-radio-group
      size="small"
      button-style="solid"
      v-model="newModel"
      @change="callAction"
    >
      <a-radio-button
        v-for="(data, i) in setting"
        :key="i"
        :value="data.value"
        class="mb-5"
      >
        {{ data.title === undefined ? data.value : data.title }}
      </a-radio-button>
    </a-radio-group>
    <div class="m-0 mt-5" v-if="custom">
      <p>กำหนดเอง</p>
      <a-input
        placeholder="จำนวนเงิน"
        type="number"
        v-model="newModel"
        @change="callAction"
      />
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  computed: {},
  props: {
    setting: { type: Array, default: [] },
    model: { value: [Number, String] },
    action: { type: String, default: "" },
    custom: { type: Boolean, default: false },
  },
  mounted() {
    this.newModel = this.model;
  },

  data() {
    return {
      newModel: 0,
    };
  },
  methods: {
    callAction() {
      this.$emit(this.action, this.newModel);
    },
    setDefault(val) {
      this.newModel = val;
    },
  },
};
</script>
