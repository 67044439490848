var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{class:[
      _vm.gamePrediction.prediction != '-'
        ? _vm.gamePrediction.prediction === 'PLAYER' ||
          _vm.gamePrediction.prediction === 'DRAGON'
          ? 'border-player'
          : 'border-banker'
        : 'border-loading' ],staticStyle:{"background-color":"transparent"},attrs:{"bordered":false}},[_c('a-card',{staticClass:"board-color",attrs:{"bordered":false}},[(_vm.gameType === 'BACCARAT' || _vm.gameType === 'DRAGON_TIGER')?_c('div',[_c('h6',[_vm._v("GAME LIVE")]),(_vm.gamePrediction.prediction === _vm.gameLive.winner)?_c('lottie-player',{staticStyle:{"width":"100%","height":"100%","position":"absolute"},attrs:{"src":"https://assets5.lottiefiles.com/packages/lf20_BmDsGtUUjh.json","background":"transparent","speed":"1","autoplay":""}}):_vm._e(),_c('div',{staticClass:"row center mt-15"},[_c('div',{staticClass:"board-half"},[_c('div',{class:['box bg-score-player text-white']},[_vm._v(" "+_vm._s(_vm.gameLive.playerScore)+" ")]),_c('div',{staticClass:"tree-card"},[(_vm.gameType === 'BACCARAT')?_c('card',{attrs:{"gameType":'player',"cards":_vm.gameLive.playerCards[2] || '',"isWin":_vm.gameLive.winner.toString() === 'PLAYER' ||
                  _vm.gameLive.winner.toString() === 'DRAGON'
                    ? true
                    : false}}):_vm._e(),(_vm.gameType === 'BACCARAT')?_c('card',{attrs:{"gameType":'player',"cards":_vm.gameLive.playerCards[1] || '',"isWin":_vm.gameLive.winner.toString() === 'PLAYER' ||
                  _vm.gameLive.winner.toString() === 'DRAGON'
                    ? true
                    : false}}):_vm._e(),_c('card',{attrs:{"gameType":'player',"cards":_vm.gameLive.playerCards[0] || '',"isWin":_vm.gameLive.winner.toString() === 'PLAYER' ||
                  _vm.gameLive.winner.toString() === 'DRAGON'
                    ? true
                    : false}})],1)]),_c('div',{staticClass:"board-half"},[_c('p',{staticStyle:{"text-align":"center"}},[_vm._v("สถานะ")]),_c('div',{staticClass:"pr-25 pl-25",staticStyle:{"text-align":"center"}},[(_vm.gameState == 'PLAYING')?_c('span',{staticClass:"blink_me card-content"},[_c('p',[_vm._v("กำลังเล่น")]),(_vm.setTimer)?_c('p',[_vm._v(_vm._s(_vm.timer))]):_vm._e(),(!_vm.setTimer)?_c('pulse-loader',{attrs:{"color":"#000","size":"10px"}}):_vm._e()],1):_vm._e(),(_vm.gameState == 'RESULTED')?_c('span',[_c('p',[_vm._v("ออกผล")])]):_vm._e(),(_vm.gameState == 'RESULTING' || _vm.gameState == 'WAITING')?_c('pulse-loader',{attrs:{"color":"#000","size":"10px"}}):_vm._e()],1)]),_c('div',{staticClass:"board-half"},[_c('div',{class:['box bg-score-banker text-white']},[_vm._v(" "+_vm._s(_vm.gameLive.bankerScore)+" ")]),_c('div',{staticClass:"tree-card"},[_c('card',{attrs:{"gameType":'banker',"cards":_vm.gameLive.bankerCards[0] || '',"isWin":_vm.gameLive.winner.toString() === 'BANKER' ||
                  _vm.gameLive.winner.toString() === 'TIGER'
                    ? true
                    : false}}),(_vm.gameType === 'BACCARAT')?_c('card',{attrs:{"gameType":'banker',"cards":_vm.gameLive.bankerCards[1] || '',"isWin":_vm.gameLive.winner.toString() === 'BANKER' ||
                  _vm.gameLive.winner.toString() === 'TIGER'
                    ? true
                    : false}}):_vm._e(),(_vm.gameType === 'BACCARAT')?_c('card',{attrs:{"gameType":'banker',"cards":_vm.gameLive.bankerCards[2] || '',"isWin":_vm.gameLive.winner.toString() === 'BANKER' ||
                  _vm.gameLive.winner.toString() === 'TIGER'
                    ? true
                    : false}}):_vm._e()],1)])]),_c('div',{staticClass:"row center"},[_c('div',{staticClass:"board-bet board-color board-bet-left"},[_c('h6',{staticClass:"center mt-5"},[_vm._v(" "+_vm._s(_vm.gameType === "BACCARAT" ? "PLAYER" : "DRAGON")+" ")]),(
                _vm.gamePrediction.prediction === 'PLAYER' ||
                _vm.gamePrediction.prediction === 'DRAGON'
              )?_c('Chip',{staticClass:"center"}):_vm._e()],1),_c('div',{staticClass:"board-bet board-color board-bet-right"},[_c('h6',{staticClass:"center mt-5"},[_vm._v(" "+_vm._s(_vm.gameType === "BACCARAT" ? "BANKER" : "TIGER")+" ")]),(
                _vm.gamePrediction.prediction === 'BANKER' ||
                _vm.gamePrediction.prediction === 'TIGER'
              )?_c('Chip',{staticClass:"center"}):_vm._e()],1)])],1):_vm._e()])],1),_c('a-row',{staticClass:"mt-10",attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":12,"lg":8,"xl":8}},[_c('div',{staticClass:"card-client"},[_c('p',{staticClass:"name-client"},[_vm._v("โต๊ะที่")]),_c('div',{staticClass:"social-media"},[_c('span',[_vm._v(" "+_vm._s(_vm.gamePrediction.tableId)+" ")])])])]),_c('a-col',{attrs:{"span":12,"lg":8,"xl":8}},[_c('div',{staticClass:"card-client"},[_c('p',{staticClass:"name-client"},[_vm._v("ตาที่")]),_c('div',{staticClass:"social-media"},[_c('span',[_vm._v(" "+_vm._s(_vm.gamePrediction.gameRound)+" ")])])])]),_c('a-col',{attrs:{"span":12,"lg":8,"xl":8,"xs":24,"md":24}},[_c('div',{staticClass:"card-client"},[_c('p',{staticClass:"name-client"},[_vm._v("วิเคราะห์ผล")]),_c('div',{staticClass:"social-media"},[_vm._v(_vm._s(_vm.gamePrediction.prediction))])])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }