<template>
  <div>
    <RouletteBoard
      ref="card_board"
      :gameType="gameType"
      :gameState="gameState"
      v-if="gameType === 'ROULETTE'"
    />
    <CardBoard
      ref="card_board"
      :gameType="gameType"
      :gameState="gameState"
      v-else
    />
  </div>
</template>

<script>
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import CardBoard from "./card/CardBoard.vue";
import RouletteBoard from "./roulette/RouletteBoard.vue";

export default {
  props: {
    gameType: { type: String, default: "BACCARAT" },
  },
  components: { PulseLoader, CardBoard, RouletteBoard },
  computed: {},
  created() {},
  mounted() {
    this.getGameLive();
    this.updateBoard();
  },
  data() {
    return {
      gameState: "",
    };
  },
  methods: {
    getGameLive() {
      try {
        let temp_positive = 0;
        let temp_negative = 0;
        this.sockets.subscribe(
          `game-${this.$route.params.game}-live`,
          (data) => {
            this.gameState = data.gameState;
            if (this.gameState === "RESULTED") {
              try {
                this.$refs.card_board.getGameLive(data.result);
              } catch (error) {
                return;
              }

              this.$refs.card_board.isSetTimer(true);

              if (data.isWin != null) {
                temp_positive += 1;
              }
              temp_negative = 0;
            }

            if (this.gameState === "PLAYING") {
              temp_positive = 0;
              temp_negative -= 1;
              try {
                this.$refs.card_board.getGamePrediction(data);
              } catch (error) {
                return;
              }
            }
            if (temp_positive === 1) {
              this.$emit("callGameHistory", data);
              this.timer = 0;
            }
            if (temp_negative === -1) {
              this.$refs.card_board.isCountDown();
              this.$refs.card_board.updateBoard();
            }
          }
        );
      } catch (error) {
        return;
      }
    },
    updateBoard() {
      this.$refs.card_board.updateBoard();
      this.sockets.unsubscribe(`mock-bot-player-BACCARAT-live`);
      this.sockets.unsubscribe(`mock-bot-player-ROULETTE-live`);
      this.sockets.unsubscribe(`mock-bot-player-DRAGON_TIGER-live`);

      this.sockets.unsubscribe(`bot-player-BACCARAT-live`);
      this.sockets.unsubscribe(`bot-player-ROULETTE-live`);
      this.sockets.unsubscribe(`bot-player-DRAGON_TIGER-live`);

      this.sockets.unsubscribe(`game-BACCARAT-live`);
      this.sockets.unsubscribe(`game-ROULETTE-live`);
      this.sockets.unsubscribe(`game-DRAGON_TIGER-live`);

      this.getGameLive();
    },
    isHideTimer() {
      this.$refs.card_board.isSetTimer(false);
    },
  },
};
</script>
<style lang="scss">
.card-client {
  background: #262626;
  width: 100%;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 10px;
  margin-top: 4px;
  text-align: center;
  color: #fff;
  transition: all 0.3s ease;
}

.card-client:hover {
  transform: translateY(-10px);
}

.name-client {
  margin: 0;
  margin-top: 4px;
  font-weight: 600;
  font-size: 14px;
}

.name-client span {
  display: block;
  font-weight: 200;
  font-size: 16px;
}

.social-media:before {
  content: " ";
  display: block;
  width: 100%;
  height: 1px;
  margin: 4px 0;
  background: rgb(121, 121, 121);
}
</style>
