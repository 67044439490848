<template>
  <div>
    <div
      :bordered="false"
      :class="[
        gamePrediction.prediction != '-'
          ? gamePrediction.prediction === 'PLAYER' ||
            gamePrediction.prediction === 'DRAGON'
            ? 'border-player'
            : 'border-banker'
          : 'border-loading',
      ]"
      style="background-color: transparent"
    >
      <!-- <a-card
      :bordered="false"
      :class="[
        gamePrediction.prediction != '-'
          ? gamePrediction.prediction === 'PLAYER' ||
            gamePrediction.prediction === 'DRAGON'
            ? 'border-loading'
            : 'border-loading'
          : 'border-loading',
      ]"
      style="background-color: transparent"
    > -->
      <a-card :bordered="false" class="board-color">
        <div v-if="gameType === 'BACCARAT' || gameType === 'DRAGON_TIGER'">
          <h6>GAME LIVE</h6>
          <lottie-player
            v-if="gamePrediction.prediction === gameLive.winner"
            src="https://assets5.lottiefiles.com/packages/lf20_BmDsGtUUjh.json"
            background="transparent"
            speed="1"
            style="width: 100%; height: 100%; position: absolute"
            autoplay
          ></lottie-player>
          <div class="row center mt-15">
            <div class="board-half">
              <div :class="['box bg-score-player text-white']">
                {{ gameLive.playerScore }}
              </div>
              <div class="tree-card">
                <card
                  :gameType="'player'"
                  :cards="gameLive.playerCards[2] || ''"
                  :isWin="
                    gameLive.winner.toString() === 'PLAYER' ||
                    gameLive.winner.toString() === 'DRAGON'
                      ? true
                      : false
                  "
                  v-if="gameType === 'BACCARAT'"
                />
                <card
                  :gameType="'player'"
                  :cards="gameLive.playerCards[1] || ''"
                  :isWin="
                    gameLive.winner.toString() === 'PLAYER' ||
                    gameLive.winner.toString() === 'DRAGON'
                      ? true
                      : false
                  "
                  v-if="gameType === 'BACCARAT'"
                />
                <card
                  :gameType="'player'"
                  :cards="gameLive.playerCards[0] || ''"
                  :isWin="
                    gameLive.winner.toString() === 'PLAYER' ||
                    gameLive.winner.toString() === 'DRAGON'
                      ? true
                      : false
                  "
                />
              </div>
            </div>

            <div class="board-half">
              <p style="text-align: center">สถานะ</p>

              <div style="text-align: center" class="pr-25 pl-25">
                <span
                  v-if="gameState == 'PLAYING'"
                  class="blink_me card-content"
                  ><p>กำลังเล่น</p>

                  <p v-if="setTimer">{{ timer }}</p>
                  <pulse-loader
                    color="#000"
                    size="10px"
                    v-if="!setTimer"
                  ></pulse-loader>
                </span>
                <!-- <span v-if="gameState == 'RESULTING'"><p>เตรียมออกผล</p> </span> -->
                <span v-if="gameState == 'RESULTED'"><p>ออกผล</p> </span>
                <pulse-loader
                  color="#000"
                  size="10px"
                  v-if="gameState == 'RESULTING' || gameState == 'WAITING'"
                ></pulse-loader>
              </div>
            </div>
            <div class="board-half">
              <div :class="['box bg-score-banker text-white']">
                {{ gameLive.bankerScore }}
              </div>
              <div class="tree-card">
                <card
                  :gameType="'banker'"
                  :cards="gameLive.bankerCards[0] || ''"
                  :isWin="
                    gameLive.winner.toString() === 'BANKER' ||
                    gameLive.winner.toString() === 'TIGER'
                      ? true
                      : false
                  "
                />
                <card
                  :gameType="'banker'"
                  :cards="gameLive.bankerCards[1] || ''"
                  :isWin="
                    gameLive.winner.toString() === 'BANKER' ||
                    gameLive.winner.toString() === 'TIGER'
                      ? true
                      : false
                  "
                  v-if="gameType === 'BACCARAT'"
                />
                <card
                  :gameType="'banker'"
                  :cards="gameLive.bankerCards[2] || ''"
                  :isWin="
                    gameLive.winner.toString() === 'BANKER' ||
                    gameLive.winner.toString() === 'TIGER'
                      ? true
                      : false
                  "
                  v-if="gameType === 'BACCARAT'"
                />
              </div>
            </div>
          </div>

          <div class="row center">
            <div class="board-bet board-color board-bet-left">
              <h6 class="center mt-5">
                {{ gameType === "BACCARAT" ? "PLAYER" : "DRAGON" }}
              </h6>
              <!-- class="center chip-anim loader" -->
              <Chip
                class="center"
                v-if="
                  gamePrediction.prediction === 'PLAYER' ||
                  gamePrediction.prediction === 'DRAGON'
                "
              />
            </div>
            <div class="board-bet board-color board-bet-right">
              <h6 class="center mt-5">
                {{ gameType === "BACCARAT" ? "BANKER" : "TIGER" }}
              </h6>
              <Chip
                class="center"
                v-if="
                  gamePrediction.prediction === 'BANKER' ||
                  gamePrediction.prediction === 'TIGER'
                "
              />
            </div>
          </div>
        </div>
      </a-card>
    </div>
    <a-row :gutter="24" class="mt-10">
      <a-col :span="12" :lg="8" :xl="8">
        <div class="card-client">
          <p class="name-client">โต๊ะที่</p>
          <div class="social-media">
            <span> {{ gamePrediction.tableId }} </span>
          </div>
        </div>
      </a-col>
      <a-col :span="12" :lg="8" :xl="8">
        <div class="card-client">
          <p class="name-client">ตาที่</p>
          <div class="social-media">
            <span> {{ gamePrediction.gameRound }} </span>
          </div>
        </div>
      </a-col>
      <a-col :span="12" :lg="8" :xl="8" :xs="24" :md="24">
        <div class="card-client">
          <p class="name-client">วิเคราะห์ผล</p>
          <div class="social-media">{{ gamePrediction.prediction }}</div>
        </div>
      </a-col>
    </a-row>
    <!-- <div>
      <p class="desc" style="text-align: center">
        โต๊ะที่ :
        {{ gamePrediction.tableId }} ตาที่ :
        {{ gamePrediction.gameRound }} วิเคราะห์ผล:
        {{ gamePrediction.prediction }}
      </p>
    </div> -->
  </div>
</template>

<script>
import card from "./Card";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import Chip from "./Chip.vue";
export default {
  props: {
    cards: Object,
    tableId: {
      type: Number,
      default: 22,
    },
    gameType: { type: String, default: "BACCARAT" },
    gameState: { type: String, default: "WATTING" },
  },
  computed: {},
  components: {
    card,
    PulseLoader,

    Chip,
  },

  data() {
    return {
      gamePrediction: { tableId: "-", gameRound: "-", prediction: "-" },
      gameLive: {
        bankerCards: ["", "", ""],
        playerCards: ["", "", ""],
        bankerScore: 0,
        playerScore: 0,
        number: 0,
        winner: ["-"],
      },

      timer: null,
      setTimer: false,
    };
  },

  methods: {
    updateBoard() {
      this.gameLive = {
        bankerCards: ["", "", ""],
        playerCards: ["", "", ""],
        bankerScore: 0,
        playerScore: 0,
        number: 0,
        winner: ["-"],
      };
      this.gamePrediction = { tableId: "-", gameRound: "-", prediction: "-" };
    },
    getGameLive(val) {
      this.gameLive = val;
    },
    getGamePrediction(val) {
      console.log(val);
      this.gamePrediction = val;
    },

    countDownTimer() {
      if (this.timer > 0) {
        setTimeout(() => {
          this.timer -= 1;
          this.countDownTimer();
        }, 1000);
      }
    },
    isSetTimer(val) {
      console.log(val, val);
      this.setTimer = val;
    },

    isCountDown() {
      if (this.setTimer) {
        this.timer = 20;
        console.log("this.setTimer", this.setTimer, this.timer);
        this.countDownTimer();
      }
    },
  },
};
</script>
<style lang="scss">
.border-player {
  border-radius: 12px;
  padding: 6px 6px;
  background-image: conic-gradient(
    from var(--border-angle),
    transparent 20%,
    #022692,
    #2a55d6
  );
  background-size: calc(100% - (var(--border-size) * 2))
      calc(100% - (var(--border-size) * 2)),
    cover;
  background-position: center center;
  background-repeat: no-repeat;

  animation: bg-spin 3s linear infinite;
  @keyframes bg-spin {
    to {
      --border-angle: 1turn;
    }
  }
}

.border-banker {
  border-radius: 12px;
  padding: 6px 6px;
  background-image: conic-gradient(
    from var(--border-angle),
    transparent 20%,
    #cc0001,
    #ed4141
  );
  background-size: calc(100% - (var(--border-size) * 2))
      calc(100% - (var(--border-size) * 2)),
    cover;
  background-position: center center;
  background-repeat: no-repeat;

  animation: bg-spin 3s linear infinite;
  @keyframes bg-spin {
    to {
      --border-angle: 1turn;
    }
  }
}

.border-loading {
  border-radius: 12px;
  padding: 6px 6px;
  background-image: conic-gradient(
    from var(--border-angle),
    transparent 20%,
    #fff,
    #fff
  );
  background-size: calc(100% - (var(--border-size) * 2))
      calc(100% - (var(--border-size) * 2)),
    cover;
  background-position: center center;
  background-repeat: no-repeat;

  animation: bg-spin 3s linear infinite;
  @keyframes bg-spin {
    to {
      --border-angle: 1turn;
    }
  }
}

@property --border-angle {
  syntax: "<angle>";
  inherits: true;
  initial-value: 0turn;
}
</style>
