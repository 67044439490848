<template>
  <!-- Orders History Timeline Card -->
  <a-card
    :bordered="false"
    class="header-solid h-full"
    :bodyStyle="{ paddingTop: '12px' }"
  >
    <template #title>
      <h6>การตั้งค่า</h6>
      <!-- <p>this month <span class="text-success">+20%</span></p> -->
    </template>
    <a-timeline pending="Recording..." :reverse="timelineReverse">
      <a-timeline-item color="green">
        Game
        <p>{{ botSetting.gameType }}</p>
      </a-timeline-item>
      <a-timeline-item color="green">
        รูปแบบการเล่น
        <p>{{ botSetting.playerBetType }}</p>
      </a-timeline-item>
      <a-timeline-item color="green">
        การเดินเงิน
        <p>{{ botSetting.betMoneyType }}</p>
      </a-timeline-item>
      <a-timeline-item color="green">
        ชิพเริ่มต้น
        <p>{{ botSetting.betAmount }}</p>
      </a-timeline-item>
      <a-timeline-item color="green">
        กำไรเป้าหมาย
        <p>
          {{ botSetting.profitTarget.amount }}({{
            botSetting.profitTarget.percent
          }}%)
        </p>
      </a-timeline-item>
      <a-timeline-item color="green">
        กำหนดขาดทุนไม่เกิน
        <p>
          {{ botSetting.lossLimit.amount }}({{ botSetting.lossLimit.percent }}%)
        </p>
      </a-timeline-item>
    </a-timeline>
  </a-card>
  <!-- / Orders History Timeline Card -->
</template>

<script>
export default {
  props: {
    botSetting: { type: Object, default: {} },
  },
  data() {
    return {
      // Whether or not the timeline in "Orders History" card is reversed.
      timelineReverse: false,
    };
  },
};
</script>
