<template>
  <div>
    <div class="modals" v-if="showModal">
      <article class="modals-container">
        <section class="modals-container-body rtf">
          <p>ยืนยันที่จะหยุดบอท</p>
        </section>
        <footer class="modals-container-footer">
          <button class="button is-primary" @click="stopBot()">ยืนยัน</button>
          <button class="button is-ghost" @click="showModal = false">
            ปิด
          </button>
        </footer>
      </article>
    </div>
    <!-- <template id="modal-template" v-if="showModal"> -->
    <!-- <template id="modal-template">
      <div class="modal">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="modal-body">ยืนยันที่จะหยุดบอท</div>
            <div class="modal-footer">
              <button @click="stopBot()">ยืนยัน</button>
              <button @click="showModal = false">ปิด</button>
            </div>
          </div>
        </div>
      </div>
    </template> -->
    <div class="wallet_fixed fade-in" v-if="!hidden">
      <div class="hidden_tag">
        <h5 class="m-0" @click="switchConsole">×</h5>
      </div>
      <a-col :span="24" :md="24">
        <a-card style="width: 300px">
          <div class="card-title">
            <p>กระเป๋าเงิน</p>
            <h4 class="m-1"><Balance /></h4>
          </div>
          <!-- <a-row class="m-5 mb-20">
            <ConsoleToggle
              :title="`แทงเสมอ`"
              :action="`sendTie`"
              :betType="betType"
              :checkedStatus="betType === 'TIE' ? true : false"
              @sendTie="sendTie"
              v-if="gameType != 'ROULETTE'"
            />
            <ConsoleToggle
              :title="`แทงสวน`"
              :action="`sendOpposite`"
              :betType="betType"
              :checkedStatus="betType === 'OPPOSITE' ? true : false"
              @sendOpposite="sendOpposite"
            />
            <ConsoleToggle
              :title="`แทงศูนย์`"
              :action="`sendZero`"
              :betType="betType"
              :checkedStatus="betType === 'ZERO' ? true : false"
              @sendZero="sendZero"
              v-if="gameType === 'ROULETTE'"
            />
          </a-row> -->

          <ConsoleButton
            v-if="botStatus === 'NULL'"
            :title="`สร้างบอท`"
            :action="`sendCreateBot`"
            :color="`primary`"
            @sendCreateBot="sendCreateBot"
          />
          <div v-else>
            <ConsoleButton
              v-if="isBotStatus === 'IDLE'"
              :title="`เริ่มบอท`"
              :action="`startBot`"
              :color="`primary`"
              :customStyle="`start`"
              @startBot="startBot"
            />
            <ConsoleButton
              v-if="isBotStatus === 'RUNNING'"
              :title="`พักบอท`"
              :action="`pauseBot`"
              :color="`primary`"
              :customStyle="`pause`"
              @pauseBot="pauseBot"
            />
            <ConsoleButton
              v-if="isBotStatus === 'PAUSED'"
              :title="`เล่นต่อ`"
              :action="`resumeBot`"
              :color="`primary`"
              :customStyle="`resume`"
              @resumeBot="resumeBot"
            />
            <ConsoleButton
              :title="`หยุดบอท`"
              :action="`stopBotModal`"
              :color="`danger`"
              @stopBotModal="stopBotModal"
            />
          </div>
        </a-card>
      </a-col>
    </div>
    <a-button
      class="fab shake-in"
      style="top: 90px; background-color: #1890ff"
      shape="circle"
      v-if="hidden"
      @click="switchConsole"
    >
      <svg
        width="40"
        height="40"
        viewBox="0 0 24.00 24.00"
        xmlns="http://www.w3.org/2000/svg"
        fill="#ffffff"
        stroke="#ffffff"
        stroke-width="0.00024000000000000003"
      >
        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
        <g
          id="SVGRepo_tracerCarrier"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke="#CCCCCC"
          stroke-width="1.248"
        ></g>
        <g id="SVGRepo_iconCarrier">
          <g>
            <path fill="none" d="M0 0h24v24H0z"></path>
            <path
              d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-4H8V8h3V6h2v2h1a2.5 2.5 0 0 1 2 4 2.5 2.5 0 0 1-2 4h-1v2h-2v-2zm-1-3v1h4a.5.5 0 1 0 0-1h-4zm0-3v1h4a.5.5 0 1 0 0-1h-4z"
            ></path>
          </g>
        </g>
      </svg>
    </a-button>
  </div>
</template>
<script>
import ConsoleButton from "./ConsoleButton";
import ConsoleToggle from "./ConsoleToggle";
import Balance from "@/views/components/wallet/Balance.vue";

export default {
  components: { ConsoleButton, ConsoleToggle, Balance },
  computed: {},
  props: {
    botStatus: { type: String, default: "NULL" },
  },
  created() {},

  data() {
    return {
      hidden: false,
      errorMsg: "",
      isBotStatus: "",
      showModal: false,
    };
  },
  methods: {
    sendTie() {
      if (this.betType === "TIE") {
        this.$http
          .post(
            `${process.env.VUE_APP_BACKEND_API_URL}/api/bot/${this.$route.params.game}/customize?isMock=${this.$store.getters.getMock}`,
            { betMoneyType: "NORMAL" }
          )
          .then(({}) => {
            this.$emit("getBot");
          })
          .catch((err) => {
            return err;
          });
      } else {
        this.$http
          .post(
            `${process.env.VUE_APP_BACKEND_API_URL}/api/bot/${this.$route.params.game}/customize?isMock=${this.$store.getters.getMock}`,
            { betMoneyType: "TIE" }
          )
          .then(({}) => {
            this.$emit("getBot");
          })
          .catch((err) => {
            return err;
          });
      }
    },
    sendOpposite() {
      if (this.betType === "OPPOSITE") {
        this.$http
          .post(
            `${process.env.VUE_APP_BACKEND_API_URL}/api/bot/${this.$route.params.game}/customize?isMock=${this.$store.getters.getMock}`,
            { betMoneyType: "NORMAL" }
          )
          .then(({}) => {
            this.$emit("getBot");
          })
          .catch((err) => {
            return err;
          });
      } else {
        this.$http
          .post(
            `${process.env.VUE_APP_BACKEND_API_URL}/api/bot/${this.$route.params.game}/customize?isMock=${this.$store.getters.getMock}`,
            { betMoneyType: "OPPOSITE" }
          )
          .then(({}) => {
            this.$emit("getBot");
          })
          .catch((err) => {
            return err;
          });
      }
    },
    sendZero() {
      if (this.betType === "ZERO") {
        this.$http
          .post(
            `${process.env.VUE_APP_BACKEND_API_URL}/api/bot/${this.$route.params.game}/customize?isMock=${this.$store.getters.getMock}`,
            { betMoneyType: "NORMAL" }
          )
          .then(({}) => {
            this.$emit("getBot");
          })
          .catch((err) => {
            return err;
          });
      } else {
        this.$http
          .post(
            `${process.env.VUE_APP_BACKEND_API_URL}/api/bot/${this.$route.params.game}/customize?isMock=${this.$store.getters.getMock}`,
            { betMoneyType: "ZERO" }
          )
          .then(({}) => {
            this.$emit("getBot");
          })
          .catch((err) => {
            return err;
          });
      }
    },
    switchConsole() {
      this.hidden ? (this.hidden = false) : (this.hidden = true);
    },
    sendCreateBot() {
      this.$emit("sendCreateBot");
    },
    updataBotStatus(val) {
      this.isBotStatus = val;
    },
    stopBotModal() {
      console.log("stopBot");
      this.showModal = true;
    },
    //Shorten
    stopBot() {
      this.showModal = false;
      this.$http
        .post(
          `${process.env.VUE_APP_BACKEND_API_URL}/api/bot/${this.$route.params.game}/stop?isMock=${this.$store.getters.getMock}`,
          {}
        )
        .then(({ data }) => {
          window.location.reload();
        })
        .catch((err) => {
          this.showModal = true;
          return;
        });
    },
    startBot() {
      this.$http
        .post(
          `${process.env.VUE_APP_BACKEND_API_URL}/api/bot/${this.$route.params.game}/start?isMock=${this.$store.getters.getMock}`,
          {}
        )
        .then(({ data }) => {
          this.isBotStatus = "RUNNING";
          this.$emit("startBot");
          this.$forceUpdate();
        })
        .catch((err) => {
          return;
        });
    },
    pauseBot() {
      this.$http
        .post(
          `${process.env.VUE_APP_BACKEND_API_URL}/api/bot/${this.$route.params.game}/pause?isMock=${this.$store.getters.getMock}`,
          {}
        )
        .then(({ data }) => {
          this.isBotStatus = "PAUSED";
          this.$forceUpdate();
        })
        .catch((err) => {
          return;
        });
    },
    resumeBot() {
      this.$http
        .post(
          `${process.env.VUE_APP_BACKEND_API_URL}/api/bot/${this.$route.params.game}/resume?isMock=${this.$store.getters.getMock}`,
          {}
        )
        .then(({ data }) => {
          this.isBotStatus = "RUNNING";
          this.$forceUpdate();
        })
        .catch((err) => {
          return;
        });
    },
  },
};
</script>
<style>
.hidden_tag {
  width: 15%;
  position: absolute;
  display: flex;
  z-index: 10000;
  top: 10px;
  right: -10px;
  cursor: pointer;
}
.wallet_fixed {
  /* max-width: 400px; */
  position: fixed;
  display: flex;
  z-index: 100;
  bottom: 0;
  right: 20px;
  /* left: 0; */
}
.shake-in {
  animation: shake 0.5s;
}
.fade-in {
  animation: fadeIn 0.5s;
}
@media (max-width: 469px) {
  .wallet_fixed {
    right: 0px;
    /* left: 0; */
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
  0% {
    bottom: -40px;
  }

  100% {
    bottom: 0;
  }
}
@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(5px);
  }
  50% {
    transform: translateX(-5px);
  }
  75% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
  }
}
</style>
