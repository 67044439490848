<!-- 
	This is the dashboard page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

<template>
  <div>
    <div class="modals" v-if="showModal">
      <article class="modals-container">
        <section class="modals-container-body rtf">
          <p>บอทดำเนินการเสร็จสิ้น</p>
        </section>
        <footer class="modals-container-footer">
          <button class="button is-ghost" @click="showModal = false">
            ปิด
          </button>
        </footer>
      </article>
    </div>

    <HistoryModal
      :gameType="gamePath"
      v-if="showHistory"
      @closeModal="showHistory = false"
    />

    <Loading v-if="loading || $store.getters.getBalance === null" />

    <a-row :gutter="24" type="flex" align="stretch">
      <a-col :span="24" :lg="12" :xl="12" class="mb-24">
        <a-card :bordered="false" class="card-deep">
          <Chart ref="chart" :gameType="gamePath" />
        </a-card>
        <a-button block type="primary" class="mt-5" @click="showHistory = true"
          >ดูประวัติการเล่น</a-button
        >
      </a-col>
      <a-col :span="24" :lg="12" :xl="12" class="mb-24">
        <!-- <CardBoard
          @callGameHistory="callGameHistory"
          ref="card_board"
          :gameType="gamePath"
        /> -->
        <Board
          @callGameHistory="callGameHistory"
          ref="board"
          :gameType="gamePath"
        />
      </a-col>
    </a-row>

    <GameLive />
    <Setting ref="setting" v-if="botStatus === 'NULL'" />
    <Result
      ref="result"
      @success="success"
      :botSetting="botSetting"
      :gameType="gamePath"
      v-else
    />
    <Console
      ref="console"
      @sendCreateBot="sendCreateBot"
      @startBot="startBot"
      :botStatus="botStatus"
    />
    <div style="margin-bottom: 160px"></div>
  </div>
</template>

<script>
import Vue from "vue";

import Loading from "./components/Loading.vue";
import Board from "./components/live/Board.vue";

// Bar chart for "Active Users" card.
import Chart from "@/views/components/chart/Chart";
import Console from "@/views/components/console/Console";
import GameLive from "@/views/components/GameLive";
import Setting from "@/views/components/setting/Setting";
import Result from "./Result";
import HistoryModal from "./components/history/historyModal.vue";

export default {
  components: {
    Setting,
    Result,
    Console,
    Chart,
    GameLive,
    Loading,
    Board,
    HistoryModal,
  },
  created() {
    this.gamePath = this.$route.params.game;
    this.getBot();
  },
  watch: {
    "$route.params.game": {
      handler: function (path) {
        this.loading = true;
        this.gamePath = path;
        this.getBot();
        this.$refs.board.updateBoard(this.gamePath);
        this.$refs.board.isHideTimer();

        this.$refs.chart.getGameHistory(this.gamePath);
        this.$refs.chart.setResultBoard(this.gamePath);

        if (this.botStatus != "NULL") {
          this.$refs.result.setBotLiveBegin();
          this.$refs.result.getBotLiveInfo(this.gamePath);
          this.$refs.result.getBotTransaction(this.gamePath);
        } else {
          this.$refs.setting.setDefaultplayerBetType(this.gamePath);
        }

        if (this.gamePath !== path) {
          this.$forceUpdate();
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      reloading: false,
      botStatus: "NULL",
      botSetting: {},
      gamePath: "",
      loading: true,
      showModal: false,
      showHistory: false,
    };
  },
  methods: {
    getBot() {
      this.$http
        .get(
          `${process.env.VUE_APP_BACKEND_API_URL}/api/bot/${this.gamePath}/current?isMock=${this.$store.getters.getMock}`
        )
        .then(({ data }) => {
          this.botSetting = data;
          this.botStatus = data.status;
          this.$refs.console.updataBotStatus(data.status);
          this.loading = false;
        })
        .catch((err) => {
          this.botStatus = "NULL";
          this.botSetting = {};
          this.loading = false;
          return err;
        });
    },
    sendCreateBot() {
      this.$refs.setting.checkValidate();
    },

    callGameHistory(val) {
      console.log(val);
      this.$refs.chart.addChart(val);
      this.$refs.chart.addResultBoard(val);
      this.$refs.chart.updateSeriesLine();
    },

    success() {
      this.showModal = true;
      this.getBot();
    },
    startBot() {
      console.log("start");
      this.$refs.result.setBotLiveBegin();
      this.$refs.result.getBotLiveInfo(this.gamePath);
      this.$refs.result.getBotTransaction(this.gamePath);
      this.$forceUpdate();
    },
  },
};
</script>

<style lang="scss">
.modals {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.7);
  z-index: 10000000001;
}

.modals-container {
  max-height: 90vh;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  background-color: rgb(0, 0, 0);
  border-radius: 16px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-shadow: 0 15px 30px 0 rgba(#000, 0.25);
  @media (max-width: 600px) {
    width: 90%;
  }
}

.modals-container-header {
  padding: 16px 32px;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modals-container-title {
  display: flex;
  align-items: center;
  gap: 8px;
  line-height: 1;
  font-weight: 700;
  font-size: 1.125;
  svg {
    width: 32px;
    height: 32px;
    color: #cc0001;
  }
}

.modals-container-body {
  padding: 24px 32px 51px;
  overflow-y: auto;
}

.modals-container-footer {
  padding: 20px 32px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid #ddd;
  gap: 12px;
  position: relative;
}

.button {
  padding: 12px 20px;
  border-radius: 8px;

  border: 0;
  font-weight: 600;
  cursor: pointer;
  transition: 0.15s ease;

  &.is-ghost {
    &:hover,
    &:focus {
      background-color: #dfdad7;
    }
  }

  &.is-primary {
    background-color: #cc0001;
    color: #fff;
    &:hover,
    &:focus {
      background-color: #a50000;
    }
  }
}

.icon-button {
  padding: 0;
  border: 0;
  background-color: transparent;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  cursor: pointer;
  border-radius: 8px;
  transition: 0.15s ease;
  svg {
    width: 24px;
    height: 24px;
  }

  &:hover,
  &:focus {
    background-color: #dfdad7;
  }
}
</style>
