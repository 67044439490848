<template>
  <div>
    <a-row :gutter="24" type="flex" align="stretch">
      <a-col :span="24" :lg="5" class="mb-24">
        <CardSettingValue :botSetting="botSetting"> </CardSettingValue>
      </a-col>
      <a-col :span="24" :lg="12" class="mb-24">
        <CardHistory
          :data="tableData"
          :columns="tableColumns"
          :gameType="gameType"
        ></CardHistory>
      </a-col>
      <a-col :span="24" :lg="7" class="mb-24">
        <div>
          <ChartBotPlayer :data="gamePlayerLive" />
        </div>
        <div class="mt-20">
          <ChartProfit ref="chart_profit" />
        </div>
      </a-col>
    </a-row>

    <a-row :gutter="24" type="flex" align="stretch">
      <a-col :span="24" :lg="24" class="mb-24"> </a-col>
    </a-row>
  </div>
</template>

<script>
import ChartProfit from "@/views/components/chart/ChartProfit";
import CardSettingValue from "@/views/components/CardSettingValue";
import CardHistory from "@/views/components/CardHistory";
import ChartBotPlayer from "./components/chart/ChartBotPlayer.vue";

export default {
  props: {
    botSetting: { type: Object, default: {} },
    gameType: { type: String },
  },
  components: {
    ChartProfit,
    CardSettingValue,
    CardHistory,
    ChartBotPlayer,
  },
  created() {
    // this.getBotHistory(this.gameType);
    // this.getBotLiveInfo(this.gameType);
    // console.log("botsetting", this.botSetting);
    this.setBotLiveBegin();
    this.getBotLiveInfo(this.gameType);
    this.getBotTransaction(this.gameType);
  },
  mounted() {},
  data() {
    return {
      tableData: [],
      gamePlayerLive: {},

      tableColumns: [
        {
          title: "รอบที่",
          dataIndex: "index",
        },
        {
          title: "โตีะที่",
          dataIndex: "tableId",
        },

        {
          title: "ฝั่งที่เล่น",
          dataIndex: "playerType",
        },
        {
          title: "ชิพที่ลง",
          dataIndex: "betAmount",
        },

        {
          title: "กำไร",
          dataIndex: "profit",
          class: "font-bold text-muted text-sm",
        },
        {
          title: "เวลา",
          dataIndex: "createdAt",

          scopedSlots: { customRender: "createdAt" },
          class: "font-bold text-muted text-sm",
        },
        // {
        //   title: "ผลการเล่น",
        //   dataIndex: "isWin",
        //   class: "font-bold text-muted text-sm",
        // },
      ],
    };
  },
  methods: {
    setBotLiveBegin() {
      this.sockets.unsubscribe(`bot-player-BACCARAT-live`);
      this.sockets.unsubscribe(`bot-player-ROULETTE-live`);
      this.sockets.unsubscribe(`bot-player-DRAGON_TIGER-live`);

      this.sockets.unsubscribe(`mock-bot-player-BACCARAT-live`);
      this.sockets.unsubscribe(`mock-bot-player-ROULETTE-live`);
      this.sockets.unsubscribe(`mock-bot-player-DRAGON_TIGER-live`);
      this.gamePlayerLive = {};
    },

    getBotLiveInfo(val) {
      if (this.$store.getters.getMock) {
        this.sockets.subscribe(`mock-bot-player-${val}-live`, (data) => {
          this.gamePlayerLive = data;
          if (data.action === "RESULTED") {
            try {
              const temp = data.bet;
              temp["index"] = this.tableData.length + 1;
              this.$refs.chart_profit.addChart(temp);
              this.tableData.unshift(temp);
              console.log(this.tableData);
            } catch (error) {
              console.log("error", error);
              return error;
            }

            this.$refs.chart_profit.updateSeriesLine();
          } else if (data.action === "ENDED") {
            this.$emit("success");
          }
        });
      } else {
        this.sockets.subscribe(`bot-player-${val}-live`, (data) => {
          this.gamePlayerLive = data;
          if (data.action === "RESULTED") {
            try {
              const temp = data.bet;
              temp["index"] = this.tableData.length + 1;
              this.$refs.chart_profit.addChart(temp);
              this.tableData.unshift(temp);
              console.log(this.tableData);
            } catch (error) {
              console.log("error", error);
              return error;
            }

            this.$refs.chart_profit.updateSeriesLine();
          } else if (data.action === "ENDED") {
            this.$emit("success");
          }
        });
      }
    },
    getBotTransaction(val) {
      this.$http
        .get(
          `${process.env.VUE_APP_BACKEND_API_URL}/api/bot/${val}/current/transaction?limit=0&isMock=${this.$store.getters.getMock}`
        )
        .then(({ data }) => {
          console.log("history", data);
          this.$refs.chart_profit.getChart(data);
          this.tableData = data;
          for (var i = 0; i < this.tableData.length; i++) {
            // this.botTransaction[i].push({ index: i });
            this.tableData[i]["index"] = this.tableData.length - i;
          }
          if (data[0].profit === 0) {
            this.tableData.splice(0, 1);
          }

          // this.$refs.chart_profit.getChart(data);
        })
        .catch((err) => {
          return err;
        });
    },
  },
};
</script>

<style lang="scss"></style>
